import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow, Typography } from "@chainsafe/common-components"
import { createStyles, ITheme, makeStyles } from "@chainsafe/common-theme"
import React from "react"
import TableOfContents from "../Elements/content/TableOfContents"

const useStyles = makeStyles(
  ({ constants, breakpoints, palette }: ITheme) => {
    return createStyles({
      root: {
        maxWidth: `${constants.readCopyWidth}px`,
        margin: "0 auto",
        display: "flex",
        flexDirection: "row",
        [breakpoints.up("md")]: {
          padding: `0 ${constants.generalUnit * 8}px ${constants.generalUnit * 8}px`,
        },
        [breakpoints.down("md")]: {
          flexDirection: "column",
        }
      },
      indexArea: {
        margin: `${constants.generalUnit * 3}px auto 0`,
      },
      index: {
        [breakpoints.up("md")]: {

        }
      },
      indexItem: {
        paddingLeft: 18,
        position: "relative",
        "& > span": {
          display: "block",
          height: 22,
          width: 14,
          position: "absolute",
          left: 0
        }
      },
      content: {
        [breakpoints.up("md")]: {
          maxWidth: "calc((100% / 12) * 8)",
          // margin: `calc(${constants.generalUnit * 3}px + ${constants.headerHeight}px) auto 0`,
          margin: `${constants.generalUnit * 3}px auto 0`,
          padding: `0 ${constants.generalUnit * 4}px`,
        },
        [breakpoints.down("md")]: {
          margin: `${constants.generalUnit * 3}px auto 0`,
          padding: `0 ${constants.generalUnit * 8}px ${constants.generalUnit * 8}px`,
        }
      },
      fixScroll: {
        "&:before":{
          display: "block",
          content: '""',
          marginTop: `-${constants.headerHeight}px`,
          height: constants.headerHeight,
          visibility: "hidden",
          pointerEvents: "none"
        }
      },
      table: {
        textAlign: "left",
        "& tr": {
          display: "table",
          borderBottom: `1px solid ${palette.common.black.main}`
        },
      },
      cta: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& a": {
          margin: `${constants.generalUnit}px 0`,
          textDecoration: "none"
        }
      }
    })
  },
)

const DataMetricsPage = () => {
  const classes = useStyles()
  return <section className={classes.root}>
    <div className={classes.indexArea}>
      <TableOfContents className={classes.index} items={[
        {
          type: "hash",
          content: <span className={classes.indexItem}><span aria-label="dart board emoji" role="img">🎯</span> Introduction </span>,
          url: "#intro"
        },
        {
          type: "hash",
          content: <span className={classes.indexItem}><span aria-label="chart emoji" role="img">📊</span> MEV-Explore </span>,
          url: "#mev-explore"
        }
        ,{
          type: "hash",
          content: <span className={classes.indexItem}><span aria-label="dart board emoji" role="img"></span> Metrics Definitions </span>,
          url: "#metrics"
        }
        ,{
          type: "hash",
          content: <span className={classes.indexItem}><span aria-label="antenna emoji" role="img">📡</span> MEV-Inspect: The Data Pipeline </span>,
          url: "#mev-inspect"
        }
        ,{
          type: "hash",
          content: <span className={classes.indexItem}><span aria-label="dart board emoji" role="img"></span> Data Coverage </span>,
          url: "#data-coverage"
        }
        ,{
          type: "hash",
          content: <span className={classes.indexItem}><span aria-label="dart board emoji" role="img"></span> Pipeline Workflow </span>,
          url: "#pipeline"
        }
        ,{
          type: "hash",
          content: <span className={classes.indexItem}><span aria-label="dart board emoji" role="img"></span> Caveats </span>,
          url: "#caveats"
        }
        ,
        {
          type: "hash",
          content: <span className={classes.indexItem}><span aria-label="dart board emoji" role="img">🚀</span> Next steps </span>,
          url: "#next-steps"
        }
        ,
        {
          type: "hash",
          content: <span className={classes.indexItem}><span aria-label="dart board emoji" role="img"></span> MEV-Inspect </span>,
          url: "#mev-inspect-next"
        }
        ,
        {
          type: "hash",
          content: <span className={classes.indexItem}><span aria-label="dart board emoji" role="img"></span> MEV-Explore </span>,
          url: "#mev-explore-next"
        }
      ]} />
    </div>
    <div className={classes.content}>
    <Typography className={classes.fixScroll} id="intro" variant="h2" component="h2">
      <span aria-label="dart board emoji" role="img">🎯</span> Introduction
    </Typography>
      <Typography component="p">
        MEV-Explore is a public dashboard of metrics related to the Extracted MEV we’ve recorded on Ethereum since Jan 1 2020.
        In this document we go over the data range and definitions we use for these metrics. We also explain how the data underpinning the dashboard is collected and structured through MEV-Inspect,
        a blockchain crawler which scans Ethereum and identifies MEV extraction activity.
      </Typography>
      <Typography component="p">
        Maximum Extractable Value (MEV), formerly named Miner Extractable Value, is a  measure of the total value that can be extracted permissionlessly (i.e. without any special rights)
        from transaction ordering. <b>MEV-Explore focuses on quantifying the MEV extracted on-chain rather than quantifying the total MEV available in any given block.
        The Extracted MEV metric in the dashboard constitutes a lower bound estimate on MEV, limited by the current set of DeFi protocols covered by our inspectors. In particular it does not cover sandwiches.</b>
      </Typography>

      <Typography component="p">
        <b>Key Info</b>
      </Typography>
      <Typography>
        <ul>
          <li>
            Data coverage is from block 9193266, the first block of Jan 1 2020
          </li>
          <li>
            We inspected 10 DeFi protocols: Aave, Balancer, Bancor, Compound, Cream, Curve, Uniswappy V2, and Uniswap v3 and 0x and will continue to expand our protocol coverage.
          </li>
          <li>
            We only cover profit for single-tx MEV opportunities. We have a sandwich detector, but it does not have profit.          </li>
          <li>
            We also do not cover CEX-DEX arbitrage since the CEX bit of the trade does not have publicly available data for us to crawl.
          </li>
          <li>
            Extracted MEV = Successful MEV transactions + Successful MEV transactions gas fees + Failed MEV transactions gas fees
          </li>
          <li>
            Our database is updated in near real time and currently has a 5 block delay.
          </li>
          <li>
            For latency reasons, the website’s data is updated every 3 hours.
          </li>
          <li>
            We normalize all our Extracted MEV calculations first to USD. We do this using Coingecko’s price for the assets with day-level granularity. We normalize all our Extracted MEV calculations first to ETH. We do so by using Uniswap's market buy price for the asset and we do not currently account for slippage.
          </li>
          <li>
            MEV-Inspect is our data collection effort that is open-sourced on Github, MEV-Explore is the UI on top of it. We welcome community contributions.
          </li>
        </ul>
      </Typography>
      <Typography variant="h2" component="h2">
        MEV-Explore v1
      </Typography>
      <Typography component="p">
      The latest version of MEV-Explore shows numbers that are different from the previous version in an attempt to more accurately estimate MEV.
      </Typography>
      <Typography component="p">
      The largest contributing factors to this are:
      </Typography>
      <Typography>
        <ul>
          <li>
          <b>Sandwiches</b>:The previous version of Explore misclassified some types of sandwiches as arbitrage. We have attempted to filter out all sandwiches from this analysis, and in the future will add in sandwich profits.
          </li>
          <li>
          <b>Uniswap V3</b>: Previously Explore did not account for any transactions that interacted with Uniswap V3.
          </li>
        </ul>
      </Typography>
      <Typography className={classes.fixScroll} id="mev-explore" variant="h2" component="h2">
        <span aria-label="chart emoji" role="img">📊</span> MEV-Explore
      </Typography>
      <Typography id="metrics" className={classes.fixScroll} variant="h3" component="h3">
        <b><span aria-label="hand writing emoji" role="img">✍️</span> Metrics Definitions</b>
      </Typography>
      <Typography component="p">
        To find Extracted MEV, we look at each Ethereum's transaction trace, parse it and go through the token transfers, do some accounting of the starting balance of the sender address and its ending balance, taking into account proxy addresses and other cases that we plan for with a set of tests to make sure the value isn’t misclassified. Read more about our data collection process in MEV-Inspect: The Data Pipeline.
      </Typography>
      <Typography component="p">
        <b>Extracted MEV</b> = Successful MEV transactions + Successful MEV transactions gas fees + Failed MEV transactions gas fees
        <br /> Failed MEV transactions gas fees = Reverted MEV transactions gas fees + Checked MEV transactions gas fees
      </Typography>
      <Typography component="p">
        <b>Successful MEV transactions</b> represent the value captured by the MEV tx sender subtracted of the gas fees they paid for it.
        <br />
        <br />
        <b>Cumulative Extracted MEV</b> is a cumulative time-series of the Extracted MEV we’ve recorded on chain since Jan 1 2020.
          We suspect our figures for early 2020 are particularly low since we do not cover protocols where most of the MEV was back then such as Bancor, Kyber, Etherdelta and Airswap.
        <br />
        <br />
        <b>Daily Extracted MEV</b> is the daily Extracted MEV we’ve recorded since Jan 1 2020. Since this is given in USD but calculated in ETH,
          the price appreciation of ETH is embedded within the chart and should be kept in mind when looking at the daily distribution.
        <br />
        <br />
        <b>Extracted MEV Split by Protocol</b> shows the breakdown of Extracted MEV since Jan 1 2020 between different platforms the transaction interacted with.
          The Extracted MEV related to the transaction is weighed equally between each protocol it interacted with. We expect to use a better heuristic in the future.
        <br />
        <br />
        <b>Extracted MEV Split by Type</b> hows the breakdown of Extracted MEV between different MEV action types.
       </Typography>
       <Typography>
        <ul>
            <li>
              We define <b>arbitrage</b> as an Ethereum transaction executing swaps where the starting balance is inferior to the ending balance.
              In the case where the two balances are in different assets, we convert both in absolute ETH terms using Uniswap market prices.
              Here is an <a rel="noopener noreferrer" target="_blank" href="https://etherscan.io/tx/0xb72689042f313adbffbe4d192b0febc4c8a8346b75a549d5b4d4795b37180488">example arbitrage transaction.</a>
            </li>
            <li>
              We define <b>liquidations</b> as Ethereum transactions executing a liquidation and will record its Extracted MEV by either
              1) looking at the Extracted MEV made from selling the collateral they acquired within the same transaction,
              2) converting the collateral to ETH by using the Uniswap price, without accounting for potential slippage from selling the collateral.
              Here is an <a rel="noopener noreferrer" target="blank" href="https://etherscan.io/tx/0x963054a6001e4711a64b5dc35a37a0eb3aaf3bb7c204f021dd5e85e7a8a51a31">example liquidation transaction.</a>
            </li>
          </ul>
       </Typography>
       <Typography component="p">
        <b>Extracted MEV Split</b> shows the breakdown of Extracted MEV between MEV Tx Sender and the MEV Tx Miner.
          The figure associated with the MEV Tx Miner is essentially the sum of all gas fees paid for MEV transactions (both failed and successful).
        <br />
        <br />
        <b>Extracted MEV Gas Usage %</b> represents the overall gas usage of Extracted MEV transactions on the Ethereum network since Jan 1 2020.
        <br />
        <br />
        <b>Total Wasted Fees on Failed MEV Txs</b> sums up the total paid out gas fees in USD amount, on the failed MEV (reverted and checked) transactions, since Jan 1 2020.
        <br />
        <br />
        <b>Implied Wasted Ethereum Blocks</b> calculates an estimated number of total “wasted” blocks, for the total wasted used gas amount. We use 12500000 as an estimate for the average gas limit used per block.
          In the future, we plan to use the historical gas usage and limits instead of the assumption of 12.5M gas/block.
      </Typography>
      {/* <Typography className={classes.fixScroll} id="mev-inspect" variant="h2" component="h2"> */}
      <Typography variant="h2" component="h2">
        Successful, Reverted and Checked MEV Txs
      </Typography>
      <Typography component="p">
        <b>Example 1: A Successful MEV transaction from a Priority Gas Auction</b><br/>
        A 3-hop arbitrage between the 3 liquidity pools Balancer ETH/USDC, Sushiswap USDC/SIL
        and Sushiswap SIL/ETH that paid 12 ETH ($14.1k at the time) in gas fees and worth 16.7 ETH ($19.6k at the time) of Extracted MEV.
        <br/>
        <a rel="noopener noreferrer" target="_blank" href="https://etherscan.io/tx/0x2bde6e654eb93c990ae5b50a75ce66ef89ea77fb05836d7f347a8409f141599f">
          https://etherscan.io/tx/0x2bde6e654eb93c990ae5b50a75ce66ef89ea77fb05836d7f347a8409f141599f
        </a>
      </Typography>
      <Typography component="p">
        The high transaction fee hints at the fact that the sender iteratively bid up the gas price of their arbitrage transaction by replacing their existing trade
        before it gets mined with one with the same nonce but a higher gas price in order to compete with other traders going for the same opportunity.
        One loser of the gas auction landed <a rel="noopener noreferrer" target="_blank" href="https://etherscan.io/tx/0x1ff41966cc02a4d8f6f1d80a6f49851e710b743d7c0ed143498608c0f421ec28">here</a> for a loss of 1.18 ETH in “checked” mev (see example 3).
        The winning transaction above ended up paying a gas price of 51.6k gwei, 547x higher than the average gas price at the time (94 gwei),
        but only 2x higher than the loser at 27.6k gwei.
      </Typography>
      <Typography component="p">
        Extracted MEV breakdown for tx #1: 4.7 ETH ($5.5k at the time) for the sender, 12 ETH ($14.1k at the time) for F2Pool, the miner of this block.
        <br/>
        Extracted MEV breakdown for tx #2:  1.18 ETH ($1.4k at the time) for F2Pool, the miner of this block.
      </Typography>

      <Typography component="p">
        <b>Example 2: A Failed (Reverted) MEV transaction</b><br/>
        A transaction that attempted to capture MEV from a Cream.Finance liquidation and failed because it ran out of gas,
        costing the sender 5.75 ETH (~$10k at the time) in gas fees.
        <br/>
        <a rel="noopener noreferrer" target="_blank" href="https://etherscan.io/tx/0x8cfb46876ce1d40250e9690482bdaaffc1f6b60e18c3405ff5e98b636840875f">
          https://etherscan.io/tx/0x8cfb46876ce1d40250e9690482bdaaffc1f6b60e18c3405ff5e98b636840875f
        </a>
      </Typography>
      <Typography component="p">
        Using 1.5M gas and spending nearly 6 ETH in fees, the transaction requested a $88K USDC flashloan in order to liquidate a Cream.Finance position.
        It began doing so before failing halfway through due to a lack of provided gas. While on the face of it, 0x8cfb4 looks innocent, its internal transactions reveal more than 237 transactions.
        This is a very sophisticated transaction with complex costly logic in EVM compute that ended up using 1.5m gas, nearly 13% of the block it was included in!
        However much gas was spent, the transaction still failed because it ran out of gas and ended up costing the sender ~5.75 ETH (~$10K at the time) in gas fees.
      </Typography>
      <Typography component="p">
        After this “Reverted” transaction, the opportunity was still available
        and <a rel="noopener noreferrer" target="_blank" href="https://etherscan.io/tx/0x2b5a515ba58b5026857b52cb845bef6ddeecbafbe8f5811c4337b5a4e1012a29">was seized</a> by
        the second best gas (or first loser) auction participant for a total Extracted MEV of 4.125 ETH where 3.75 ETH was paid in gas fees.
      </Typography>
      <Typography component="p">
        Extracted MEV breakdown for tx #1: $0 for the sender and ~5.75 ETH (~$9.3K at the time) for Sparkpool, the miner of this block.
        <br/>
        Extracted MEV breakdown for tx #2: 0.375 ETH ($600 at the time) for sender #2 and 3.75 ETH (~$6k at the time) for Sparkpool, the miner of this block.
      </Typography>
      <Typography className={classes.fixScroll} id="mev-inspect" variant="h2" component="h2">
        <span aria-label="satalite dish" role="img">📡</span> MEV-Inspect: The Data Pipeline
      </Typography>
      <Typography component="p">
        MEV-Explore uses data collected by <a rel="noopener noreferrer" target="_blank" href="https://github.com/flashbots/mev-inspect-py" >MEV-Inspect</a>.
        One can see Explore as the front-end and Inspect as the back-end of the same effort to systematically collect and classify Ethereum data relevant to transaction prioritization.
      </Typography>
      <Typography component="p">
        MEV Inspect is responsible for fetching each transaction’s trace and “decoding” it using protocol-specific inspectors and action-specific reducers. For detailed information on how each inspector is implemented, we refer you to the <a rel="noopener noreferrer" target="_blank" href="https://docs.flashbots.net/flashbots-data/mev-inspect-py/overview">mev-inspect docs</a>.
        MEV Inspect is responsible for fetching each transaction’s trace and “decoding” it using protocol-specific inspectors and action-specific reducers. For detailed information on how each inspector is implemented, we refer you to the <a rel="noopener noreferrer" target="_blank" href="https://github.com/flashbots/mev-inspect-py">mev-inspect docs</a>.
      </Typography>
      <Typography id="data-coverage" className={classes.fixScroll} variant="h3" component="h3">
        <b><span aria-label="orange chart emoji" role="img">📶</span> Data Coverage</b>
      </Typography>
      <Typography component="p">
        <b> Block Number </b>
      </Typography>
      <Typography component="p">
        Block 9,193,266 - the oldest block in our database.
      </Typography>
      <Typography component="p">
        <b> Date Range </b>
      </Typography>
      <Typography component="p">
        Jan-01-2020 12:00:11 AM +UTC - block time of the oldest block in our database.
        <br />Last 30d means 30 days from the UTC timestamp of the most recent block in our database.
        <br />Last 24h means 24 hours from the UTC timestamp of the most recent block in our database.
      </Typography>
      <Typography component="p">
        <b> Related Actions In Scope </b>
      </Typography>
      <Typography>
        <ul>
          <li>
            trades
          </li>
          <li>
            single-transaction arbitrage
          </li>
          <li>
            liquidations
          </li>
        </ul>
      </Typography>
      <Typography component="p">
        <b> Data Source </b>
      </Typography>
      <Typography>
        <ul>
          <li>
            <span aria-label="check emoji" role="img">✅</span> <b>Traces data</b> for extracting actions
          </li>
          <li>
            <span aria-label="check emoji" role="img">✅</span> <b>Blocks data</b> for block info (miner, timestamps, etc.)
          </li>
          <li>
            <span aria-label="check emoji" role="img">✅</span> <b>Transactions data</b> for transaction details (operator/bot address, gas used, etc.)
          </li>
          <li>
            <span aria-label="check emoji" role="img">✅</span> <b>Prices data</b> from CoinGecko API for calculating profits
          </li>
          <li>
            <span aria-label="cross emoji" role="img">❌</span> <b>Mempool data</b> (we do not currently use mempool data in any of the metrics displayed)
          </li>
        </ul>
      </Typography>
      <Typography component="p">
        <b> Inspected Protocols </b>
      </Typography>
      <Table className={classes.table} fullWidth={true}>
        <TableHead>
          <TableRow type="grid">
            <TableHeadCell align="left">
              Protocols \ Action Types
            </TableHeadCell>
            <TableHeadCell align="left">
              arbitrage
            </TableHeadCell>
            <TableHeadCell align="left">
              liquidation
            </TableHeadCell>
            <TableHeadCell align="left">
              attacks/exploits
            </TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow type="grid">
            <TableCell align="left">
              Aave
            </TableCell>
            <TableCell align="left">
              <span aria-label="cross emoji" role="img">❌</span>
            </TableCell>
            <TableCell align="left">
              <span aria-label="check emoji" role="img">✅</span>
            </TableCell>
            <TableCell align="left">
              <span aria-label="cross emoji" role="img">❌</span>
            </TableCell>
          </TableRow>
          <TableRow type="grid">
            <TableCell align="left">
              Compound
            </TableCell>
            <TableCell align="left">
              <span aria-label="cross emoji" role="img">❌</span>
            </TableCell>
            <TableCell align="left">
              <span aria-label="check emoji" role="img">✅</span>
            </TableCell>
            <TableCell align="left">
              <span aria-label="cross emoji" role="img">❌</span>
            </TableCell>
          </TableRow>
          <TableRow type="grid">
            <TableCell align="left">
              Uniswap V2 (and clones)
            </TableCell>
            <TableCell align="left">
              <span aria-label="check emoji" role="img">✅</span>
            </TableCell>
            <TableCell align="left">
              -
            </TableCell>
            <TableCell align="left">
              <span aria-label="cross emoji" role="img">❌</span>
            </TableCell>
          </TableRow>
          <TableRow type="grid">
            <TableCell align="left">
              Uniswap V3
            </TableCell>
            <TableCell align="left">
              <span aria-label="check emoji" role="img">✅</span>
            </TableCell>
            <TableCell align="left">
              -
            </TableCell>
            <TableCell align="left">
              <span aria-label="cross emoji" role="img">❌</span>
            </TableCell>
          </TableRow>
          <TableRow type="grid">
            <TableCell align="left">
              Curve
            </TableCell>
            <TableCell align="left">
              <span aria-label="check emoji" role="img">✅</span>
            </TableCell>
            <TableCell align="left">
              -
            </TableCell>
            <TableCell align="left">
              <span aria-label="cross emoji" role="img">❌</span>
            </TableCell>
          </TableRow>
          <TableRow type="grid">
            <TableCell align="left">
              Balancer
            </TableCell>
            <TableCell align="left">
              <span aria-label="check emoji" role="img">✅</span>
            </TableCell>
            <TableCell align="left">
              -
            </TableCell>
            <TableCell align="left">
              <span aria-label="cross emoji" role="img">❌</span>
            </TableCell>
          </TableRow>
          <TableRow type="grid">
            <TableCell align="left">
              Bancor
            </TableCell>
            <TableCell align="left">
              <span aria-label="check emoji" role="img">✅</span>
            </TableCell>
            <TableCell align="left">
              -
            </TableCell>
            <TableCell align="left">
              -
            </TableCell>
          </TableRow>
          <TableRow type="grid">
            <TableCell align="left">
              0x
            </TableCell>
            <TableCell align="left">
              <span aria-label="check emoji" role="img">✅</span>
            </TableCell>
            <TableCell align="left">
              -
            </TableCell>
            <TableCell align="left">
              -
            </TableCell>
          </TableRow>

        </TableBody>
      </Table>
      <Typography className={classes.fixScroll} id="pipeline" variant="h3" component="h3">
        <b><span aria-label="crane emoji" role="img">🏗</span> Pipeline Workflow: How we collect the data</b>
      </Typography>
      <Typography component="p">
        The data collection and structuring process is as follows:
      </Typography>
      <Typography component="p">
        <b>Part 1 - Raw Data Parser </b>
        <br />Using an Erigon archive node with tracing enabled, we inspect every transaction using protocol inspectors and reducers. These inspected transactions are then inserted in a PostgreSQL database for further querying and analysis.
      </Typography>
      <Typography component="p">
        By inspecting, we are able to learn the full token flow during the transaction, as well as get higher order information, e.g. the profit amount and token of an arbitrage transaction.
      </Typography>
      <Typography component="p">
        <b>Part 2 - Metrics Aggregation</b>
        <br /> After all transactions have been fully inspected, we "flatten" the information we have found so far to simple labels and normalize the Extracted MEV to USD. Block level, protocol level and action type level of aggregation table are then built with different indices for querying. This pipeline runs and updates the downstream tables and dashboards every 5 mins.
      </Typography>
      <Typography id="caveats" className={classes.fixScroll} variant="h3" component="h3">
        <b><span aria-label="paper emoji" role="img">📝</span> Caveats</b>
      </Typography>
      <Typography component="p">
        The following caveats apply to our methodology:
      </Typography>
      <Typography component="p">
        <b>Extracted MEV Covered Only </b>
        <br />This only reflects Extracted MEV and not all of the MEV opportunities. In other words, we are currently only quantifying <i>extracted value</i> and not the theoretical total <i>extractable</i> value.
      </Typography>
      <Typography component="p">
        <b>Reflects Lower Bound of Actual Values </b>
        <br />MEV-Inspect provides a lower-bound approach to quantifying Extracted MEV. The more MEV Inspectors are added, the closer we get to a true figure for Extracted MEV.
      </Typography>
      <Typography component="p">
        <b>No Address Grouping </b>
        <br />We cannot assign transactions coming from different addresses as a single actor automatically. In practice, we may group certain address senders together based on patterns we manually observe.
      </Typography>
      <Typography component="p">
        <b>No Multi-tx MEV Covered </b>
        <br />Our current methodology only accounts for single-tx MEV opportunities. This means sandwich trades are not accounted for (yet). However if any part of a sandwich trade is an arbitrage, the arbitrage will be picked up by our inspectors.
      </Typography>
      <Typography component="p">
        <b>No CEX-DEX Arbitrage Included </b>
        <br />Arbitrage trades between centralized and decentralized trading venues are not tracked nor quantified given the opaqueness of the CEX part of CEX-DEX arbitrage.
      </Typography>
      <Typography component="p">
        <b>Price Oracles</b>
        <br />Normalizing from ETH to USD is done using the minute ETH price for 2020. For 2021, we use the ETH price per block by taking the average of ETH-DAI, ETH-USDT and ETH-USDC prices given by Uniswap V2's oracle.
        <br />Normalizing from $TOKEN to ETH is done by using Uniswap's market buy price for the asset, we do not account for slippage.
      </Typography>
      <Typography className={classes.fixScroll} id="next-steps" variant="h2" component="h2">
        <span aria-label="rocket emoji" role="img">🚀</span> Next Steps
      </Typography>
      <Typography id="mev-inspect-next" className={classes.fixScroll} variant="h3" component="h3">
        <b><span aria-label="antenna emoji" role="img">📡</span> MEV-Inspect</b>
      </Typography>
      <Typography component="p">
        <b>Expand Protocol Coverage</b>
        <br />Add more classifiers that expand protocol coverage to new protocols. For example, we want to expand coverage to new DEXes.
      </Typography>
      <Typography component="p">
        <b>Expand Strategy Coverage</b>
        <br />Support multi-transaction types of MEV, such as identifying sandwiching and backrunning arbitrage.
      </Typography>
      <Typography component="p">
        <b>Expand Chain Coverage</b>
        <br />Add support for other EVM chains
      </Typography>
      <Typography className={classes.cta} component="p">
        <a rel="noopener noreferrer" target="_blank" href="http://github.com/flashbots/mev-explore-public">
          <span aria-label="high five emoji" role="img">🙏</span> Contribute feedback, report bugs and suggest new features to MEV-Explore
        </a>
        <a rel="noopener noreferrer" target="_blank" href="http://github.com/flashbots/pm">
          <span aria-label="lightning bolt emoji" role="img">⚡️</span><span aria-label="robot head emoji" role="img">🤖</span>Find out more about Flashbots
        </a>
      </Typography>
    </div>
  </section>
}

export default DataMetricsPage
