import { DashboardUrls } from "../Contexts/DashboardContext/Types";

export const USD_DASHBOARDS: DashboardUrls = {
  landingPage: "https://data.flashbots.net/public/dashboard/12568e65-103a-4f61-a45c-ab8c4770b266#tilted=false",
  leaderboards :[
    {
      name: "Latest MEV transactions",
      link: "https://data.flashbots.net/public/dashboard/cd0026e4-5fa7-44be-bc88-5eb5935a5e06#titled=false",
    },
    {
      name: "Top MEV transactions (all time)",
      link: "https://data.flashbots.net/public/dashboard/b841d006-da8d-42e9-ae82-0d4143548340#titled=false",
    },
  ]
}
