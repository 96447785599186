import { Link, Typography,  } from "@chainsafe/common-components"
import { createStyles, ITheme, makeStyles } from "@chainsafe/common-theme"
import React from "react"
import TableOfContents from "../Elements/content/TableOfContents"
import DiscordIcon from "../Elements/icons/Discord.icon"
import GithubIcon from "../Elements/icons/Github.icon"
import { ROUTE_LINKS } from "../FilesRoutes"

const useStyles = makeStyles(
  ({ constants, breakpoints, palette }: ITheme) => {
    return createStyles({
      root: {
        maxWidth: `${constants.readCopyWidth}px`,
        display: "flex",
        margin: "0 auto",
        flexDirection: "row",
        [breakpoints.up("md")]: {
          padding: `0 ${constants.generalUnit * 8}px ${constants.generalUnit * 8}px`,
        },
        [breakpoints.down("md")]: {
          flexDirection: "column",
        }
      },
      indexArea: {
        margin: `${constants.generalUnit * 3}px auto 0`,
      },
      index: {
        [breakpoints.up("md")]: {

        }
      },
      content: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        flexWrap: "wrap",
        [breakpoints.up("md")]: {
          maxWidth: "calc((100% / 12) * 8)",
          // margin: `calc(${constants.generalUnit * 3}px + ${constants.headerHeight}px) auto 0`,
          margin: `${constants.generalUnit * 3}px auto 0`,
          // padding: `0 ${constants.generalUnit * 0}px ${constants.generalUnit * 8}px`,
        },
        [breakpoints.down("md")]: {
          flexDirection: "column",
          margin: `${constants.generalUnit * 3}px auto 0`,
          padding: `0 ${constants.generalUnit * 2}px ${constants.generalUnit * 4}px`,
        },
        [breakpoints.down("sm")]: {
        },
        "& > *": {
          display: "block"
        },

      },
      about: {
        width: "100%",
        padding: `0 ${constants.generalUnit * 5}px ${constants.generalUnit * 4}px`
      },
      getInvolved: {
        "& a": {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          color: palette.primary.main,
          padding: constants.generalUnit * 3,
          "& svg": {
            display: "block",
            width: 50,
            height: 50,
            marginBottom: constants.generalUnit
          }
        }
      },
      links: {
        display: "flex",
        flexDirection: "row",
        [breakpoints.down("sm")]: {
          flexDirection: "column"
        },
      },
      cta: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& a": {
          margin: `${constants.generalUnit}px 0`,
          textDecoration: "none"
        }
      }
    })
  },
)

const FAQPage = () => {
  const classes = useStyles()

  return <section className={classes.root}>
     <div className={classes.indexArea}>
      <TableOfContents className={classes.index} title="List of Resources" items={[
        {
          type: "external",
          content: <><GithubIcon />MEV-Inspect Repo</>,
          url: "https://github.com/flashbots/mev-inspect-py"
        },
        {
          type: "external",
          content: <><GithubIcon />MEV-Explore Discussion Forum</>,
          url: "https://github.com/flashbots/mev-explore-public"
        },
        {
          type: "external",
          content: <><GithubIcon />MEV Research Vault</>,
          url: "https://github.com/flashbots/mev-research#ship-vault"
        },
        {
          type: "external",
          content: <><GithubIcon />Mev Roast Archive</>,
          url: "https://github.com/flashbots/pm#join-the-mev-roast"
        },
        {
          type: "external",
          content: <><DiscordIcon />Flashbots Discord</>,
          url: "https://discord.gg/7hvTycdNcK"
        }
      ]} />
    </div>
    <section className={classes.content}>
      <div className={classes.about}>
        <Typography component="h2" variant="h2">
          A Collective Effort in Bringing Transparency to MEV
        </Typography>
        <Typography component="p">
            MEV-explore came into existence to help reduce information asymmetry by shedding light on MEV activities. 
          Thanks to the Flashbots core developers and community contributors to MEV-Explore and MEV-Inspect through v0 and v1.

          <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/gakonst" >Georgios Konstantopoulos</a>, <a rel="noopener noreferrer" target="_blank" href="https://github.com/jparyani" >Jason Paryani</a>, <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/epheph" >Scott Bigelow</a>, <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/taarushv" >Taarush Vemulapalli</a>, <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/guiiheise" >Gui Heise</a>, <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/onewayfunctions" >Austin Williams</a>, <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/bertcmiller" >Robert Miller</a>, <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/lvanseters" >Luke Van Seters</a>, <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/sui414" >Danning Sui</a>, <a rel="noopener noreferrer" target="_blank" href="https://github.com/RyRy79261" >Ryan Noble</a>, <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/anisimov_andrei" >Andrei Anisimov</a> and <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/tomhschmidt" >Tom Schmidt</a>.
        </Typography>
        <Typography component="p">
          If you would like to join us on our mission and to contribute to MEV-inspect, start <a rel="noopener noreferrer" target="_blank" href="https://github.com/flashbots/mev-inspect-py" >here</a>.
        </Typography>
        <Typography component="h2" variant="h2">
          Frequently Asked Questions (FAQ)
        </Typography>
        <Typography component="h3" variant="h3">
          <span aria-label="question mark" role="img">❓</span> What is MEV?
        </Typography>
        <Typography component="p">
          MEV (<b>Maximal Extractable Value</b>) is the total value that can be extracted permissionlessly (i.e. without any special rights) from the re-ordering, insertion or censorship of transactions within a block being produced. 
          As miners currently have the ultimate say on transaction ordering and inclusion in Ethereum, they can be seen as the most powerful player in this game, hence the commonly used term Miner Extractable Value. 
          Yet, MEV exists on any blockchain and layers where there is a party responsible for transaction ordering (eg. validators, rollup providers).
          <br /> Most of the MEV extracted on Ethereum today is captured by traders seizing opportunities where transaction ordering matters such as arbitrages and liquidations.
        </Typography>

        <Typography component="h3" variant="h3">
          <span aria-label="question mark" role="img">❓</span> Are you quantifying all MEV in this dashboard? 
        </Typography>
        <Typography component="p">
          Most of the MEV extracted on Ethereum today is captured by traders seizing profit opportunities where transaction ordering matters such as arbitrages and liquidations. 
          MEV-Explore focuses on quantifying the MEV extracted on-chain rather than quantifying the total MEV available in any given block. The Extracted MEV metric in the dashboard constitutes a lower bound on MEV, 
          limited by the current set of DeFi protocols covered by our inspectors. See the <Link to={ROUTE_LINKS["Data & Metrics"]}>Data & Metrics page</Link> for more details.
        </Typography>

        <Typography component="h3" variant="h3">
          <span aria-label="question mark" role="img">❓</span> Why MEV as Maximal Extractable Value instead of Miner Extractable Value? 
        </Typography>
        <Typography component="p">
          On Ethereum today, and before it moves to proof-of-stake consensus, miners have the most power with respect to transaction inclusion and ordering as they are the block producers. 
          Hence the term MEV was initially introduced as miner extractable value by Phil Daian et al. in the 2019 research paper&nbsp;
          <i><a rel="noopener noreferrer" target="_blank" href="https://arxiv.org/pdf/1904.05234.pdf" >Flashboys 2.0: Frontrunning, Transaction Reordering, and Consensus Instability in Decentralized Exchanges</a> by Daian et. al.</i>&nbsp;
          However, MEV exists on all smart contract blockchains where there is a party responsible for transaction ordering, 
          including non-miner actors such as validators in ETH2.0 and rollup providers on Optimistic Rollups. 
          Therefore we propose to rename MEV as maximal extractable value, broadening the scope to cover other blockchain architectures, 
          while still being ‘backwards compatible’ with its initial name (ie. hinting at the upper bound set by the rights of the most powerful actor 
          with respect to transaction ordering).
        </Typography>
        <Typography component="p">
          Taking the word <i>miner</i> out of MEV’s name also addresses another common confusion: that miners are the only ones who capture this value. As mentioned above, 
          the reality is that MEV extraction on Ethereum so far has been primarily conducted by non-mining DeFi traders and bot operators. 
        </Typography>

        <Typography component="h3" variant="h3">
          <span aria-label="question mark" role="img">❓</span> Why MEV-Explore?
        </Typography>
        <Typography component="p">
          MEV is currently opaque to users of Ethereum. It requires significant data analysis and in-depth knowledge of smart contracts to understand as it involves 
          transactions with complex, sometimes obfuscated, logic and adversarial games played on several meta-levels in the mempool, at times called the Dark Forest.
        </Typography>
        <Typography component="p">
          MEV-Explore is meant to help the community understand and quantify the significance of these activities and their impact on the Ethereum network. 
          We aim to preserve Ethereum’s transparency by providing data that is accessible and comprehensible to every user.
        </Typography>
        <Typography component="p">
          We are taking a lower bound approach to quantifying MEV. There are more protocols to cover, more complex interactions to classify, 
          and more useful data visualizations to create in order to bring the figures and data we show closer to the truth.
        </Typography>

        <Typography component="h3" variant="h3">
          <span aria-label="question mark" role="img">❓</span> What is Flashbots?
        </Typography>
        <Typography component="p">
          As more and more security-critical infrastructure moves off-chain, and as chain state and size grows, Ethereum’s opaqueness will only get worse and it will become increasingly difficult to leverage one of the original promises of cryptocurrency: transparency.
        </Typography>
        <Typography component="p">
          Flashbots is a research and development organization formed to mitigate the negative externalities and existential risks posed by miner-extractable value (MEV) to smart-contract blockchains. We propose a permissionless, transparent, and fair ecosystem for MEV extraction to reinforce the Ethereum ideals.
        </Typography>

        <Typography component="h3" variant="h3">
          <span aria-label="question mark" role="img">❓</span> Is Flashbots extracting MEV?
        </Typography>
        <Typography component="p">
          Flashbots does not actively participate in proprietary MEV extraction activities. Our efforts are currently centered around providing open-source example implementations and toolings for third-party MEV searchers within the Flashbots community.
        </Typography>
        
        <Typography className={classes.cta} component="p">
          <a rel="noopener noreferrer" target="_blank" href="http://github.com/flashbots/mev-explore-public">
            <span aria-label="high five emoji" role="img">🙏</span> Contribute feedback, report bugs and suggest new features to MEV-Explore
          </a>
          <a rel="noopener noreferrer" target="_blank" href="http://github.com/flashbots/pm">
            <span aria-label="lightning bolt emoji" role="img">⚡️</span><span aria-label="robot head emoji" role="img">🤖</span>Find out more about Flashbots 
          </a>
        </Typography>
      </div>
    </section>
  </section>
}

export default FAQPage
