import {
  createStyles,
  ITheme,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@chainsafe/common-theme"
import React, { useState } from "react"
import { Modal, Typography } from "@chainsafe/common-components"

const useStyles = makeStyles(
  ({ constants, palette }: ITheme) => {
    return createStyles({
      root: {
      },
      fob: {
        position: "fixed",
        cursor: "pointer",
        bottom: constants.generalUnit,
        left: constants.generalUnit,
        padding: constants.generalUnit,
        borderRadius: "100%",
        backgroundColor: palette.additional.turquoise as string,
        color: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: constants.generalUnit * 5,
        width: constants.generalUnit * 5,
        "& span": {
          margin: 0,
          padding: 0,
          fontWeight: 600,
          fontSize: 16
        }
      },
      inner: {
        padding: `${constants.generalUnit * 2}px ${constants.generalUnit * 2}px`,
        borderRadius: "5px"
      },
      footer: {
        display: "flex",
        height: 50,
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        "& > *": { 
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "& span": {
            padding: `0 ${constants.generalUnit}px`
          }
        }
      }
    })
  },
)

const VersionNotice: React.FC = () => {
  const classes = useStyles()
  const { breakpoints } = useTheme<ITheme>()
  const mobile = useMediaQuery(breakpoints.down("md"))
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <div className={classes.root}>
      <Modal 
        active={modalOpen} 
        closePosition="none"
        setActive={setModalOpen}
        injectedClass={{
          inner: classes.inner
        }}
      >
        <Typography component="h2" variant="h1">
          Notice
        </Typography>
        <Typography component="p">
          If you see any issue in this data, please share and reach out on our <a href="https://discord.com/invite/flashbots">Discord</a>! Meanwhile, we've recently published a new version of the <a href="https://transparency.flashbots.net/">Transparency Dashboard</a>. See the detailed blog <a href="https://collective.flashbots.net/t/will-the-real-mev-please-stand-up/1686/1">here</a>.
        </Typography>
      </Modal>
      {
        !mobile && <div onClick={() => setModalOpen(true)} className={classes.fob}>
          <span aria-label="warning emoji" role="img">⚠️</span>
        </div>
      }
      {
        mobile && <footer onClick={() => setModalOpen(true)} className={classes.footer}>
          <Typography><span aria-label="warning emoji" role="img">⚠️</span> Notice <span aria-label="warning emoji" role="img">⚠️</span></Typography>
        </footer>
      }
    </div>
  )
}

export default VersionNotice
