import { Button } from "@chainsafe/common-components"
import { createStyles, ITheme, makeStyles } from "@chainsafe/common-theme"
import clsx from "clsx"
import React, { useState } from "react"
import Iframe from "react-iframe"
import { useDashboard } from "../../Contexts/DashboardContext/DashboardContext"
import { LeaderboardTab } from "../../Contexts/DashboardContext/Types"

const useStyles = makeStyles(
  ({ constants, breakpoints }: ITheme) => {
    return createStyles({
      root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        height: `calc(100vh - ${constants.headerHeight}px)`,
      },
      tabs: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        flexWrap: "wrap",
        marginTop: constants.generalUnit * 2,
        marginBottom: constants.generalUnit * 2,
        [breakpoints.down("sm")]: {
          justifyContent: "space-between",
        },
      },
      tab: {
        flex: "1 1 0",
        margin: `0 ${constants.generalUnit}px ${constants.generalUnit}px`,
        padding: `${constants.generalUnit}px !important`,
        "&.active":{
          backgroundColor: "var(--gray)",
        },
        [breakpoints.down("sm")]: {
          minWidth: "45%"
        },
      },
      table: {
        display: "none !important",
        visibility: "hidden",
        opacity: 0,
        flex: "1 1 0",
        width: "100%",
        "&.active": {
          display: "block !important",
          visibility: "visible",
          opacity: 1,
        },
        "& iframe": {
          minHeight: "300px",
        }
      },
    })
  },
)

const LeaderboardPage = () => {
  const classes = useStyles()

  const [activeTable, setActiveTable] = useState(0)

  const { endpoints } = useDashboard()

  return <section className={classes.root}>
    <div className={classes.tabs}>
      {
        endpoints.leaderboards.map((tab: LeaderboardTab, index: number) => (
          <Button className={clsx(classes.tab, {
            "active": activeTable === index
          })} key={`button-${index}`} onClick={() => setActiveTable(index)}>
            {tab.name}
          </Button>
        )
      )
      }
    </div>
    {
      endpoints.leaderboards.map((tab: LeaderboardTab, index: number) => <Iframe className={clsx(
        classes.table, {
          "active": index === activeTable
        }
      )} key={`table-${index}`} url={tab.link} />)
    }
  </section>
}

export default LeaderboardPage
