import React from "react"
import { ThemeSwitcher } from "@chainsafe/common-theme"
import {
  Router,
} from "@chainsafe/common-components"
import FilesRoutes from "./Components/FilesRoutes"
import AppWrapper from "./Components/Layouts/AppWrapper"
import { lightTheme } from "./Themes/LightTheme"
import { DashboardProvider } from "./Contexts/DashboardContext/DashboardContext"

const App: React.FC<{}> = () => {
  return (
    <ThemeSwitcher themes={{ light: lightTheme }}>
      <DashboardProvider>
        <Router>
          <AppWrapper>
            <FilesRoutes />
          </AppWrapper>
        </Router>
      </DashboardProvider>
    </ThemeSwitcher>
  )
}

export default App
